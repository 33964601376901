import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Fuse from 'fuse.js'

const Blog = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  const [search, setSearch] = React.useState('');
  const [result, setResult] = React.useState([]);

  const options = {
    keys: ['node.frontmatter.title']
  }

  React.useEffect(() => {
    const fuse = new Fuse(posts, options)
    setResult(fuse.search(search))
  }, [search])


  return (
    <Layout>
      <SEO
        title="Blog"
        description="This is the homepage of my blog, where I write about technology, software, finance, and other topics that interest me."
      />
      <h1>Search my blog:</h1>
      <div id="search-container">
        <input type="text" id="search-input" placeholder="Search through the blog posts..." onChange={e => setSearch(e.target.value)} />
        <ul id="results-container">
          {result.map(({ item }) => {
            const { node } = item;
            const { frontmatter, fields } = node;
            const { slug } = fields;
            const { title } = frontmatter;
            return <Link to={`/${slug}`}><h2 style={{ textAlign: 'left' }}>{title}</h2></Link>
          })}
        </ul>
      </div>

      <h1>Browse the full archives:</h1>

      <div className="posts">
        {posts.map(({ node }) =>
          <article className="post">

            <h2><Link to={`/${node.fields.slug}`}>{node.frontmatter.title}</Link></h2>

            <div className="entry" dangerouslySetInnerHTML={{ __html: node.excerpt }}>

            </div>

            <Link to={`/${node.fields.slug}`} className="read-more">Read More</Link>
          </article>
        )}
      </div>
    </Layout>
  )
}
export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, filter: {fields: {type: {eq: "blog"}}}) {
      edges {
        node {
          excerpt
          fields {
            slug
            type
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`